.cards {
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
  margin-top: 12px;
  padding: 20px;
  width: 30%;
  min-width: 280px;
  position: relative;
  height: auto;
}

.customModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customModal > p {
  font-weight: 600;
  font-size: 14px;
}

.mt30 {
  margin-top: 30px;
}

.mt25 {
  margin-top: 25px;
}

.react-responsive-modal-modal {
  max-width: 620px !important;
  height: 320px !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.pacModal {
  width: 162px;
  height: 115px;
}

.pavlov {
  width: 59px;
  margin-left: 10px;
}

.dashHead {
  display: flex;
  align-items: center;
}

.tablevalue > td {
  text-align: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
}
.totalvalue > td {
  text-align: center;
  padding: 16px;
  font-size: 15px;
  font-weight: 700;
}

.bottomLogoNav {
  width: 92px;
  height: 32px;
  margin-left: 81px;
}

.card-text {
  font-weight: 600;
  font-size: 12px;
  margin-top: 12px;
  line-height: 20px;
}

.bottom {
  /* position: absolute; */
  /* bottom: 7%; */

  /* overflow-wrap: break-word; */
  text-align: center;
}

table > tr > th {
  background-color: #d37498 !important;
  color: white !important;
}

table {
  border: 1px solid #b7b7b7;
}

.section {
  overflow-x: auto !important;
}

.doughnut-div {
  width: 50%;
  max-width: 150px;
  margin: auto;
}

.doughnut-div-main {
  display: flex;
}
.percent-value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.month {
  text-align: center;
  font-size: 20px;
}

.totalHours {
  text-align: center;
}

.font {
  color: #d37498;
  font-size: 12px;
}

.withoutcolor {
  font-size: 12px;
}

.colored {
  /* color: #D37498; */
  margin-bottom: -3px;
}

.hidden {
  visibility: hidden;
}

.monthCard {
  padding: 20px;
  width: 45%;
  height: 336px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
}

/* .headMain {

    font-size: "24px";
} */

@media only screen and (max-width: 1070px) {
  .monthCard {
    height: 366px;
  }
  .agendaTable {
    width: 100%;
    overflow-x: auto;
  }
  
}

@media only screen and (max-width: 945px) {
  .monthCard {
    height: 386px;
  }
}

@media only screen and (max-width: 827px) {
  .monthCard {
    height: 406px;
  }
}

@media only screen and (max-width: 807px) {
  .monthCard {
    height: 425px;
  }
}

@media only screen and (max-width: 790px) {
  .monthCard {
    height: 475px;
  }

  .headMain {
    font-size: 24px;
  }
  .agendaTable {
    width: 100%;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 728px) {
  .monthCard {
    height: 575px;
  }
}

@media only screen and (max-width: 635px) {
  .agendaTable {
    width: 100%;
    overflow-x: auto;
  }
  .headingMain {
    font-size: 26px !important;
  }
  .monthCard {
    height: 675px;
  }
}

@media only screen and (max-width: 594px) {
  .monthCard {
    height: 805px;
  }
}

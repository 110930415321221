.error {
    margin-left: 5%;
    color: red;
}

.subsCard{
    display: flex;
}
.card-input-element {
    display: none;
}
.card-input:hover {
    cursor: pointer;
}
.panel-head{
  
    width: 287px;
height: 217px;
padding: 30px;
border: 2px solid #D39FB2;
box-sizing: border-box;
border-radius: 20px;
}

.continue-button{
    width: 320px;
}

.container {
    max-width: 900px;
    margin: 0 auto;
  }
  .Subscription-text {
    color: #141414;
    font-family: Suranna;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;

}
  
  
  /**
    Component
  **/
  
  label {
      width: 100%;
  }
  
  .card-input-element {
      display: none;
  }
  
  .card-input {
      margin: 10px;
      padding: 00px;
  }
  
  .card-input:hover {
      cursor: pointer;
  }
  
  .card-input-element:checked + .card-input {
       background: #D39FB2;
        border: 3px solid #D39FB2;
        box-sizing: border-box;
        border-radius: 20px;
   }

   .card-input-element:checked + .card-input .title {
    color: #FFF2CC;

   }

   .card-input-element:checked + .card-input .panel-head{
       border: none;
   }


  
  

   .subtitle{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
   text-align: center;
    color: #141414;
   }
   .subtitle2{
    font-family: Montserrat;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
   text-align: center;
    color: #141414;
   }

   .title{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 29px;
    text-align: center;
    
    color: #D37498;

    
   }
   .flexd{
       display: flex;
   }

   .register-form-layout1 {
    margin-left: 18%;
}

.mt120{
    margin-top: 120px;
}

.btn-trial {
    font-family: Montserrat;
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: center;
color: #141414;
height: 54px;
width: 378px;
border-radius: 20px;
border: 2px solid #D39FB2;
align-self: center;
justify-content: center;
align-items: center;
cursor: pointer;
}
.btn-trial:disabled {
    color: #14141450;
border: 2px solid #D39FB250;

}

@media screen and (min-device-width: 1100px)  and (max-device-width: 1490px)  {
    .register-form-layout1 {
        margin-left: 24%;
    }
    .Subscription-text {
        color: #141414;
        font-family: Suranna;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 105px;
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .size_btn{
        width: 300px;
    }
    
  }

  @media screen and (min-device-width: 770px)  and (max-device-width: 1080px)  {
    
    .register-form-layout1 {
        margin-left: 24%;
    }
    .Subscription-text {
        
        font-size: 48px;
        
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .size_btn{
        width: 300px;
    }
    
  }

  
  @media screen and (min-device-width: 548px)  and (max-device-width: 768px) {
  
    .register-form-layout1 {
        margin-left: 0%;
    }
    .Subscription-text {
        
        font-size: 48px;
        
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .subsCard {
        display: flex;
        margin-left: 4%;
    }
    
.forgot-password-text-sub {
   
    margin-left: 27px;
}
  }
  @media screen and (max-device-width:447px) {
    .continue-button{
        width: 220px;
    }
}

@media screen and (max-device-width:780px) {
    .Subscription-text{
        color: #ff0053;
    }
}
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Suranna&display=swap);
.is-horizontal-center {
  justify-content: center;
}

.column {
  padding-top: 0px;
}

.bottom-logo {
  width: 231px !important;
  height: 80px;
}

.role-button {
  width: 15rem;
  border: none;
}

.role-button-student {
  background-color: #D37498;
  color: #FFFFFF;
}

.role-button-trainee {
  background-color: #2F2E2E;

  color: #FFFFFF;
}

.role-button-trainee:hover {
  background-color: #2F2E2E;

  color: #D37498;
}

.button-container {
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

button {
  background: none;
  border: none;
  outline: inherit;
  font-size: 12px;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .log {
    width: 500px !important;
  }

  .bottom-logo {
    width: 251px !important;
  }
}

@media only screen and (min-width: 700px) {
  .log {
    margin-left: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .log {
    margin-left: 30px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1800px) {}
.has-background {
  background-image: url(/static/media/right_side_background.15b5badf.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 100vh;
}

.has-light-background {
  background-image: url(/static/media/right-side-light-background.9ad14066.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 100vh;
}

.section {
  width: 100%;
}

.has-left-background {
  background-image: url(/static/media/left_side_background.9db51bf2.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}

.has-left-light-background {
  background-image: url(/static/media/left_side_background.9db51bf2.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}

.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.remember-me-layout {
  margin-left: 4%;
  width: 100vh;
  color: #141414;
}

.forgot-pass {
  color: #141414;
  text-decoration: underline;
  width: 100%;
}

.welcome-text {
  width: 100%;
  margin-left: 70px;
  font-family: Suranna;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  color: #141414;
}

.registration-text {
  color: #141414;
  font-family: Suranna;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  text-align: center;
  line-height: 63px;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.register-image {
  width: 271px;
  height: 147px;
  margin-left: 12%;
}

.label {
  color: #141414;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-left: 15px;
}

.has-input-background {
  box-sizing: border-box;
  border: 1px solid #939597;
  border-radius: 5px;
}

.signin-button {
  background: #d37498;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}

.form-layout {
  margin-left: 2%;
}

.register-form-layout {
  margin-left: 5%;
}

.signup-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #141414;
}

.bottom-text-div {
  text-align: center;
}

.log-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #141414;
}

.Logo {
  width: 271px;
  height: 147px;
  margin-left: 30%;
}

.row-flex .checkbox {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: #141414;
  font-size: 12px;
  margin-left: 10px;
}

.customFlex {
  width: 500px;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 20%;
  }

  .customFlex {
    width: 307px;
  }

  .forgot-password-text-sub {
    margin-left: 63px;
  }
}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {
  .column.is-one-quarter,
  .column.is-one-quarter-tablet {
    flex: none;
    width: 45%;
  }

  .column.is-one-third {
    width: 45%;
  }

  .forgot-password-text-sub {
    margin-left: 50px !important;
  }

  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 18%;
  }

  .customFlex {
    width: 600px;
    margin-left: -12px;
  }
}

@media screen and (max-device-width: 780px) {
  .log-text,
  .signup-text,
  .register-checkbox-text {
    color: #ff0053;
  }

  .Logo,
  .register-image {
    background-color: whitesmoke;
    border-radius: 12px;
  }
}

@media screen and (min-device-width: 750px) and (max-device-width: 1500px) {
  .welcome-text {
    margin-left: 40px;
  }
}

@media screen and (min-device-width: 548px) and (max-device-width: 768px) {
  .customFlex {
    width: 307px;
  }
}

@media screen and (min-device-width: 425px) and (max-device-width: 700px) {
  .welcome-text {
    margin-left: 12%;
  }

  .Logo {
    width: 271px;
    height: 147px;
  }

  .customFlex {
    width: 307px;
  }

  .form-layout {
    margin-left: 0%;
  }

  .forgot-password-text-sub {
    font-size: 12px;
    line-height: 15px;

    margin-left: 10px !important;
  }
}

@media screen and (max-device-width: 420px) {
  .welcome-text {
    margin-left: 9%;
    font-size: 35px;
  }
}

@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .forgot-password-text-sub {
    font-size: 12px;
    line-height: 15px;

    margin-left: 10px !important;
  }

  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 12%;
  }

  .customFlex {
    width: 307px;
  }

  .form-layout {
    margin-left: 0%;
  }
}

@media screen and (max-device-width: 375px) {
  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 0%;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .welcome-text {
    margin-left: 9%;
    font-size: 35px;
  }

  .forgot-password-text-sub {
    font-size: 12px;
    line-height: 15px;

    margin-left: 10px;
  }

  .form-layout {
    margin-left: 0%;
  }

  .customFlex {
    width: 307px;
  }

  .log-text {
    font-size: 14px;
  }
}

.forgot-password-text-sub {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #141414;
  margin-left: 100px;
}

.cancel-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  color: #141414;
}

@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .welcome-text {
    font-size: 40px;
  }
}

@media screen and (max-device-width: 360px) {
  .forgot-password-text-sub {
    font-size: 12px;
    margin-left: 10px;
  }
}

@media screen and (max-device-width: 780px) {
  .cancel-text {
    color: #ff0053;
  }
}

@media screen and (min-device-width: 548px)
  and (max-device-width: 768px) {
    .customFlex {
        
        width: 307px;
    }
    
  }
.Register__error {
  margin-left: 1%;
  color: red;
}

.terms-checkbox {
  width: 16px;
  height: 16px;
}

.register-checkbox-text {
  margin-left: 16px;
  word-wrap: break-word;
  font-size: small;
  text-align: right;
}
.register-checkbox-text>a {
  text-decoration: none;
  color: #D37498;
  font-weight: bold;
}
.has-left-background {
  background-image: url(/static/media/left_side_background.9db51bf2.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}

.supervisor-signup-text {
  margin-left: 2px;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .register-image {
    width: 271px;
    height: 147px;
  }

  .registration-text {
    font-size: 48px;

    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .register-form-layout {
    margin-left: 23%;
  }

  .register-form-layout2 {
    margin-left: 0%;
  }

  .register-terms-layout {
    margin-left: 5%;
    width: 80%;
    color: #141414;
  }

  .register-terms-layout2 {
    margin-left: 20px;
    width: 80%;
    color: #141414;
  }

  .checkbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: #141414;
    font-size: 14px;
    /* margin-left: 30%; */
  }

  .checkbox2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: #141414;
    font-size: 14px;
    margin-left: 0;
  }

  .signup-text {
    font-size: 12px;
    margin-top: -20px;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }
}

.column.is-half {
  flex: none;
  width: 70%;
}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {
  .column.is-half {
    flex: none;
    width: 70%;
  }

  .register-image {
    width: 271px;
    height: 147px;
  }

  .registration-text {
    font-size: 48px;

    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .register-form-layout {
    margin-left: 40%;
  }

  .checkbox {
    font-size: 14px;
    margin-left: 18%;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }
}

@media screen and (max-device-width: 540px) {
  .column.is-half {
    width: 100%;
  }
}

@media screen and (min-device-width: 548px) and (max-device-width: 768px) {
  .column.is-half {
    flex: none;
    width: 70%;
  }

  .register-image {
    width: 271px;
    height: 147px;
  }

  .registration-text {
    font-size: 48px;

    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .register-form-layout {
    margin-left: 0%;
  }
}

@media screen and (min-device-width: 425px) and (max-device-width: 700px) {
  .register-form-layout {
    margin-left: 0%;
  }
}

@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .register-form-layout {
    margin-left: 0%;
  }

  .signup-text {
    font-size: 14px;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }

  .register-checkbox-text {
    word-wrap: break-word;
    font-size: 10px !important;
    text-align: center;
  }

  .checkbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: #141414;
    font-size: 9px !important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .register-image {
    width: 271px;
    height: 147px;
    margin-left: 0%;
  }

  .register-form-layout {
    margin-left: 0%;
  }

  .register-checkbox-text {
    word-wrap: break-word;
    font-size: 8px !important;
    text-align: center;
  }

  .checkbox {
    font-size: 9px !important;
  }

  .signup-text {
    font-size: 14px;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }

  .registration-text {
    font-size: 35px;
    margin-left: 12%;
  }
}

@media screen and (max-device-width: 780px) {
  .register-checkbox-text,
  .registration-text {
    color: #ff0053;
  }
}

.Toggle_btn{
    display: none;
}
.headingMain {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #2F2E2E;
    margin-bottom: 20px;
}

h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #6B3434;
    line-height: 32px;
}
h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    line-height: 50px;
}
h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666;
    line-height: 40px;
}
p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;

}
ul {
    list-style:inherit;
    list-style-type: disc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    margin-left: 24px;

}

@media screen and (max-device-width: 576px)   {
    .Toggle_btn{
        display: block;
        font-size: 30px;
        color: #4B2929;
    }
    h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        color: #6B3434;
        line-height: 32px;
    }
  }
.termAndCondText{
    margin-left: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #2F2E2E;
}
.headingMain {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #2F2E2E;
    margin-bottom: 20px;
}
h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #6B3434;
    line-height: 32px;
}
h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    line-height: 50px;
}
h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666;
    line-height: 40px;
}
p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;

}
ol {
    list-style:inherit;
    list-style-type: disc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    margin-left: 24px;

}

.error {
    margin-left: 5%;
    color: red;
}

.subsCard{
    display: flex;
}
.card-input-element {
    display: none;
}
.card-input:hover {
    cursor: pointer;
}
.panel-head{
  
    width: 287px;
height: 217px;
padding: 30px;
border: 2px solid #D39FB2;
box-sizing: border-box;
border-radius: 20px;
}

.continue-button{
    width: 320px;
}

.container {
    max-width: 900px;
    margin: 0 auto;
  }
  .Subscription-text {
    color: #141414;
    font-family: Suranna;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    align-self: center;
    justify-content: center;
    align-items: center;

}
  
  
  /**
    Component
  **/
  
  label {
      width: 100%;
  }
  
  .card-input-element {
      display: none;
  }
  
  .card-input {
      margin: 10px;
      padding: 00px;
  }
  
  .card-input:hover {
      cursor: pointer;
  }
  
  .card-input-element:checked + .card-input {
       background: #D39FB2;
        border: 3px solid #D39FB2;
        box-sizing: border-box;
        border-radius: 20px;
   }

   .card-input-element:checked + .card-input .title {
    color: #FFF2CC;

   }

   .card-input-element:checked + .card-input .panel-head{
       border: none;
   }


  
  

   .subtitle{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
   text-align: center;
    color: #141414;
   }
   .subtitle2{
    font-family: Montserrat;
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
   text-align: center;
    color: #141414;
   }

   .title{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 29px;
    text-align: center;
    
    color: #D37498;

    
   }
   .flexd{
       display: flex;
   }

   .register-form-layout1 {
    margin-left: 18%;
}

.mt120{
    margin-top: 120px;
}

.btn-trial {
    font-family: Montserrat;
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0em;
text-align: center;
color: #141414;
height: 54px;
width: 378px;
border-radius: 20px;
border: 2px solid #D39FB2;
align-self: center;
justify-content: center;
align-items: center;
cursor: pointer;
}
.btn-trial:disabled {
    color: #14141450;
border: 2px solid #D39FB250;

}

@media screen and (min-device-width: 1100px)  and (max-device-width: 1490px)  {
    .register-form-layout1 {
        margin-left: 24%;
    }
    .Subscription-text {
        color: #141414;
        font-family: Suranna;
        font-style: normal;
        font-weight: normal;
        font-size: 48px;
        line-height: 105px;
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .size_btn{
        width: 300px;
    }
    
  }

  @media screen and (min-device-width: 770px)  and (max-device-width: 1080px)  {
    
    .register-form-layout1 {
        margin-left: 24%;
    }
    .Subscription-text {
        
        font-size: 48px;
        
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .size_btn{
        width: 300px;
    }
    
  }

  
  @media screen and (min-device-width: 548px)  and (max-device-width: 768px) {
  
    .register-form-layout1 {
        margin-left: 0%;
    }
    .Subscription-text {
        
        font-size: 48px;
        
        align-self: center;
        justify-content: center;
        align-items: center;
    }
    .subsCard {
        display: flex;
        margin-left: 4%;
    }
    
.forgot-password-text-sub {
   
    margin-left: 27px;
}
  }
  @media screen and (max-device-width:447px) {
    .continue-button{
        width: 220px;
    }
}

@media screen and (max-device-width:780px) {
    .Subscription-text{
        color: #ff0053;
    }
}
.error {
  margin-left: 5%;
  color: red;
}

.customInput1 {
  width: 215px;
}
.customInput2 {
  width: 215px;
}
.customInput5 {
  width: 187px;
}
.customInput6 {
  width: 187px;
}

.paymentInputDiv {
  display: flex;
  margin-bottom: 20px;
}
.paymentInputDiv > div {
  margin-left: 0px;
}
.paymentCancelText {
  margin-left: 30%;
}
.addPaymentText {
  color: #2f2e2e;
  font-family: Suranna;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 65px;
  align-self: center;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
}
.ml0 {
  margin-left: 0% !important;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .column.is-half,
  .column.is-half-tablet {
    flex: none;
    width: 82%;
  }
  .addPaymentText {
    font-size: 48px;
  }
  .paymentInputDiv {
    display: flex;
    margin-left: 20% !important;
    margin-bottom: 20px;
  }
  .paymentCancelText {
    margin-left: 27% !important;
  }
}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {
  .column.is-half {
    flex: none;
    width: 100%;
  }
  .addPaymentText {
    font-size: 40px;
  }
  .paymentInputDiv {
    display: flex;
    margin-left: 25% !important;
    margin-bottom: 20px;
  }
  .paymentCancelText {
    margin-left: 57% !important;
  }
  .customInput5 {
    width: 140px;
  }
  .customInput6 {
    width: 140px;
  }
  .register-form-layout {
    margin-left: 29%;
  }
}

@media screen and (min-device-width: 548px) and (max-device-width: 768px) {
  .column.is-half,
  .column.is-half-tablet {
    flex: none;
    width: 95%;
  }
  .addPaymentText {
    font-size: 48px;
  }
  .paymentInputDiv {
    display: flex;
    margin-left: -2% !important;
    margin-bottom: 20px;
  }
  .paymentCancelText {
    margin-left: 48% !important;
  }
  .customInput5 {
    width: 335px;
  }
  .customInput6 {
    width: 335px;
  }
}

@media screen and (max-device-width: 780px) {
  .addPaymentText {
    color: #ff0053;
  }
}

.btnself {
        width: 510px;
}

@media only screen and (max-width: 1300px) {

        .btnself {
                width: 480px
        }
}

@media only screen and (max-width: 1260px) {

        .btnself {
                width: 450px
        }
}

@media only screen and (max-width: 1190px) {

        .btnself {
                width: 400px
        }
}

@media only screen and (max-width: 1090px) {

        .btnself {
                width: 380px
        }
}

@media only screen and (max-width: 970px) {

        .btnself {
                width: 360px
        }
}

@media only screen and (max-width: 900px) {

        .btnself {
                width: 320px
        }
}

@media only screen and (max-width: 490px) {

        .btnself {
                width: 220px
        }
}

/* @media screen and (min-device-width: 900px) and (max-device-width: 1300px) {
} */
.Register__error {
    margin-left: 1%;
    color: red;
}

.text-sub {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6B3434;
    margin-left: 8%;
    }

.no-internet-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}
h1,
h2 {
  font-weight: normal;
}
h1 {
  margin: 30px;
  margin-top: 100px;
  padding: 0.15 em;
  font-size: 150px;
  text-shadow: 0 1px 1px #aaa;
}

h2 {
  margin-bottom: 1em;
}
.desc {
  color: #999;
}

.sdp,
.stp {
  --font: "Montserrat", sans-serif;
  --shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  --shadow-md: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    #0000001a 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  --theme-color: #FFF;
  --selected-date-color: 
  #D37498;

  --disabled-color: #bdbdbd;
}
.sdp {
  width: 450px;
  height: auto;
  border: none;
  border-radius: 0.5rem;
  background-color: var(--theme-color);
  padding: 0.75rem;
  box-shadow: 0px 6px 9px 0px #14141442;

}

.sdp:focus,
.sdp--month-name:focus,
.sdp--square-btn:focus {
  outline: none;
}

.sdp--text {
  font-weight: 500;
  font-size: 24px;
  color: #141414;
  margin: 5px;
  font-family: var(--font);
}

.sdp--text__inactive {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #FFF;
    pointer-events: none;
}

.sdp--square-btn {
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  border: none;
  background-color: var(--theme-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: var(--font);
}

.sdp--square-btn:focus:hover {
  box-shadow: none;
}

.sdp--square-btn__shadowed {
  box-shadow: none;
}

.sdp--square-btn__shadowed:focus:hover {
  box-shadow: none;
}

.sdp--date-btn__selected {
  height: 34px;
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: var(--font);
  background-color: var(--selected-date-color);
  color: #FFF2CC;
}

.sdp--date-btn {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  border: none;
  background-color: var(--theme-color);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: var(--font);

}

.sdp--month-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
}
.sdp--arrows {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.sdp--dates-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 4px;
  gap: 4px;
  margin: 0.5rem 0 0;
  justify-items: center;
}

.sdp--dates-grid > p.sdp--text {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #14141450;
  pointer-events: none;
  padding: 0.5rem 0;
  font-weight: 500;
}
.sdp--month-name {
  font-family: Montserrat;
  font-size: 25px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
}

.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.date-logs {
    padding-left: 16px;
}

.date-logs>p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B3434;
    margin-bottom: 24px;
}

.date-logs .Flagtable {
    margin-bottom: 30px;
}

.date-logs .Flagtable>tr>th {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #F4DEEA;
    border: none;
    color: #141414;
}

.date-logs .Flagtable>tr>td {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    vertical-align: middle;
    height: 45px;
}

.date-logs .name {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
}

.date-logs .tableFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-logs .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
}

.date-logs .text-button {
    height: 28px;
    width: auto;
    background: #FFFFFF;
    border-radius: 30px;
    color: #6B3434;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.date-logs .primary-button {
    height: 28px;
    width: 70px;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.date-logs .secondary-button {
    height: 28px;
    width: 70px;
    background: 
    #2F2E2E;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 233px;
    border-radius: 0px;
    box-shadow: 0px 4px 6px 0px #00000040;
    background: #FFFFFF;
    padding: 10px;

}

.delete-dialog>p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;

}

.row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.delete-dialog .primary-button {
    height: 28px;
    width: 45%;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog .secondary-button {
    height: 28px;
    width: 45%;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {

    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}



@media screen and (min-device-width: 425px) and (max-device-width: 700px) {}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {}
.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.flagged-logs {
    padding-left: 16px;
}

.flagged-logs>p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B3434;
    margin-bottom: 24px;
}

.flagged-logs .Flagtable {
    margin-bottom: 30px;
}

.flagged-logs .Flagtable>thead>tr>th {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #C6707030;
    border: none;
    color: #141414;
}

.flagged-logs .Flagtable>tbody>tr>td {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    vertical-align: middle;
    height: 45px;
}

.flagged-logs .name {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
}

.flagged-logs .tableFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flagged-logs .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
}

.flagged-logs .text-button {
    height: 28px;
    width: auto;
    background: #FFFFFF;
    border-radius: 30px;
    color: #6B3434;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.flagged-logs .primary-button {
    height: 28px;
    width: 70px;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.flagged-logs .secondary-button {
    height: 28px;
    width: 70px;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 233px;
    border-radius: 0px;
    box-shadow: 0px 4px 6px 0px #00000040;
    background: #FFFFFF;
    padding: 10px;

}

.delete-dialog>p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;

}

.row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.delete-dialog .primary-button {
    height: 28px;
    width: 45%;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog .secondary-button {
    height: 28px;
    width: 45%;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {

    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}



@media screen and (min-device-width: 425px) and (max-device-width: 700px) {}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {}
.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.pending-logs {
    padding-left: 16px;
}

.pending-logs>p {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B3434;
    margin-bottom: 24px;
}

.pending-logs .Flagtable {
    margin-bottom: 30px;
}

.pending-logs .Flagtable>tr>th {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #F4DEEA;
    border: none;
    color: #141414;
}

.pending-logs .Flagtable>tr>td {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    vertical-align: middle;
    height: 45px;
}

.pending-logs .name {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
}

.pending-logs .tableFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pending-logs .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
}

.pending-logs .text-button {
    height: 28px;
    width: auto;
    background: #FFFFFF;
    border-radius: 30px;
    color: #6B3434;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.pending-logs .primary-button {
    height: 28px;
    width: 70px;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.pending-logs .secondary-button {
    height: 28px;
    width: 70px;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 233px;
    border-radius: 0px;
    box-shadow: 0px 4px 6px 0px #00000040;
    background: #FFFFFF;
    padding: 10px;

}

.delete-dialog>p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;

}

.row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.delete-dialog .primary-button {
    height: 28px;
    width: 45%;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog .secondary-button {
    height: 28px;
    width: 45%;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {

    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}



@media screen and (min-device-width: 425px) and (max-device-width: 700px) {}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {}
.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.trainees {
    padding-left: 16px;
}

.trainees>p {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: right;
    color: #6B3434;
    margin-bottom: 16px;
    width: 100%;
}

.trainees .Traineetable {
    margin-bottom: 30px;
    box-shadow: 0px 3px 7px 0px #00000040;
    height: auto;
    width: 100%;
    border-radius: 5px;
    border-collapse: collapse;
  
}

.trainees .Traineetable>tr>th {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #D37498;
    border: none;
    color: #141414;
}

.trainees .Traineetable>tr>td {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    vertical-align: middle;
    height: 49px;
    border: none;
    padding: 0 25px;
    background-color: transparent;
}
.trainees .Traineetable>tr {
    height: 49px;
}
.trainees .Traineetable>tr:nth-child(even) {
    background-color: #F6E9CD4D;
}

.trainees .name {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
        color: #D37498;
}
.trainees .delete-btn {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #6B3434;
}

.trainees .tableFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trainees .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 230px;
}

.trainees .text-button {
    height: 28px;
    width: auto;
    background: #FFFFFF;
    border-radius: 30px;
    color: #6B3434;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.trainees .primary-button {
    height: 28px;
    width: 70px;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.trainees .secondary-button {
    height: 28px;
    width: 70px;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 233px;
    border-radius: 0px;
    box-shadow: 0px 4px 6px 0px #00000040;
    background: #FFFFFF;
    padding: 10px;

}

.delete-dialog>p {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;

}

.row {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
}
.row-start {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}
.row-space-evenly {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.delete-dialog .primary-button {
    height: 28px;
    width: 45%;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.delete-dialog .secondary-button {
    height: 28px;
    width: 45%;
    background: #C67070;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {

    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}



@media screen and (min-device-width: 425px) and (max-device-width: 700px) {}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {}
.mt20{
    margin-top: 20px;
}
.profile .primary-button {
    height: 46px;
    width: 204px;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 30px;
}
.profile .label {
    font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #6B3434;
margin-left: 0px;
margin-bottom: 6px;
}

.profile .text-input {
    font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #6B3434;
height: 36px;
}

.button[disabled] {
    background-color: #aaa;
    opacity: 1;
}
.button:hover {
    color: #FFF;
}
.mtPrompt{
    margin-top: 240px;
}
.promptText{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;

color: #6B3434;
}
input[type=radio] {
    vertical-align: middle;
}
.column.is-one-fifths,
.column.is-one-fifths-tablet {
    flex: none;
}
.monthly-document .label {
    font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #141414;
margin-left: 0;
margin-top: 60px;
}
.monthly-document .dropdownCustom {
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-color: #4B2929;
    border-radius: 5px;
    color: #141414;
    height: 36px;
    padding: 0 10px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 40px;
}
.monthly-document .dropdownCustom2 {
    width: 250px;
    background-color: #fff;
    border:none;
    color: #141414;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

input[type="checkbox"] {
    vertical-align: middle;
}
.selectBtn {
    -webkit-appearance: none;
            appearance: none;
    display: inline;
}

.monthlyTable>tr>td {
    border: none;
}

.monthlyTable>tr>td>select {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    width: 70px;
    height: 30px;
    border: 1px solid #6B3434;
    box-sizing: border-box;
    border-radius: 5px;
    color: #6B3434;
}

.checkSize {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid #000000;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 10px 10px;
    margin-left: 0;
}

.select:not(.is-multiple) {
    height: 35px;
}

.HRline {
    width: 100%;
    height: 0px;
    left: 255px;
    top: 640px;
    border: 1px solid #6B3434;
}

.clr1 {
    background: #81DFED;
    border: 1px solid #81DFED;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    margin: 5px 15px;
    height: 22px;
}

.clr2 {
    background: #62B4BF;
    border: 1px solid #62B4BF;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.clr3 {
    background: #D37498;
    border: 1px solid #D37498;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.secondSectionMonthlyDocumnet {
    display: flex;
    align-items: center;
}

.ml160 {
    margin-left: 200px;
}

.mt10 {
    margin-top: 10px;
}

.secondSectionMonthlyDocumnet>div>p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #6B3434;

}

.colordtext>div>span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #6B3434;
    padding: 0px;

}

.Heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #6B3434;

}

.mt5 {
    margin-top: 5px;
}

.headerChart {
    width: 100%;
    height: 96px;
    text-align: center;
    display: flex;
    background: #D37498;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    background: #D37498;
}

.headerChart>p {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-align: center;

    color: #FFFFFF;

}

.contactAgendaInput {
    border: none;
    background-color: transparent;
    margin-left: 10px;
}

.agendaSec1 {
    display: flex;
}

.agendaSec1>div>p {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #141414;
    margin: 20px;

}
.checkbox-label {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #141414;
    margin: 20px;
}
.checkbox-label>span {
    vertical-align: middle;

}
.subHeading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    color: #141414;
}

.agendasec2 {
    display: flex;
    justify-content: space-between;
}
.agenda-table>tr>th {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;    
}
.agenda-table>tr>td {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;    
}

.colSize1 {
    width: 100px;
}

.checkText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    color: #141414;

}

.textareA {
    width: 100%;
}

table>tr>td {
    color: black;
}

label {
    color: black;
}

.dropdownCustomize {
    width: 40%;
}

p,
label {
    color: black;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.inputagenda {
    border: none;
    border-bottom: 1px solid #B7B7B7;
    margin-left: 15px;
}

.check {
    display: flex;
    flex-wrap: wrap;
    width: 45%;
}

.check>* {
    width: 50%;
    height: 30px;
}

.check>p {
    padding: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    /* identical to box height */


    color: #141414;

}

.inputFlex {
    display: flex;
    justify-content: space-between;

}

.inputagenda2 {
    border: none;
    border-bottom: 1px solid #B7B7B7;
    width: 320px;
}

.agendaTable>table>tr>td {
    height: 25px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;
    vertical-align: middle;
}

.agendaTable>table>tr>th {
    height: 30px;
    text-align: center;
    vertical-align: middle;

}

.pro-sidebar .pro-menu .pro-menu-item.active {
    color: #6B3434;
    font-weight: bold;
}


.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 10px;
    font-size: 20px;
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    flex-grow: 1;
    font-size: 16px;
    flex-shrink: 1;
}

.agendaTableHeader {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;
    vertical-align: middle;

}.agendaTableText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    color: #141414;

}
.page-unauthorized {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.page-unauthorized>h1 {
    font-size: 150px;
    font-weight: 900;
    margin-bottom: 20px;
    color: #dd1010a1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Standard */
}

.page-unauthorized>h3 {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
    color: #9d9191;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Standard */

}

.page-unauthorized>p {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
    color: #9d9191;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Standard */
    text-align: center;

}

.page-unauthorized>button {
    padding: 15px 30px;
    background-color: #ff0000a1;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 20px;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
}
.page-unauthorized>button:hover{
    color: #a49292;

}
.logForm {

    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
    padding: 20px;

}

.sectiondashboard {
    min-height: 100vh;
    height: 100%;
}

.logForm>label {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    margin: 0;
    margin-top: 20px;
}

.logForm .log-item {
    margin-top: 1px;
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    color:#141414;

}
.logForm .log-item-selected {
    margin-top: 1px;
    border: 2px solid #D37498;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    color:#D37498;


}
.logForm .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.logForm .primary-button {
    height: 42px;
    width: 48%;
    background: 
    #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.logForm .secondary-button {
    height: 42px;
    width: 48%;
    background: 
    #2F2E2E;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}
.logForm .text-button {
    height: 18px;
    width: auto;
    background: #FFFFFF;
    border-radius: 30px;
    color: #141414;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    }
.logForm .buttons>p{
    font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: center;
color: #C67070;
margin: 16px 0;
}
.logForm .full-width {
    width: 80%;
    margin: 0 5%;
}
.Register__error {
    margin-left: 1%;
    color: red;
    font-size: 12px;
}

.dropdownCustom {
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    height: 30px;
}

.logForm>textarea {
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 20px !important;
}

.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.date-picker {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    padding: 0px 15px;
    margin-bottom: 20px;
    border: 1px solid #4B2929;
}

.date-picker:focus {
    border-color: #485fc7;
    box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
    outline: none;
}


@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {

    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}



@media screen and (min-device-width: 425px) and (max-device-width: 700px) {}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {}
.attachment{
    background: rgba(247, 233, 205, 0.3);
border: 1px dashed #C4C4C4;
box-sizing: border-box;
width: 530px;
height: 190px;
}

.add-files{
    text-align: center;
}
.DragText{
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 64px;



text-align: center;

color: #000000;
}
.DropText{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 28px;

text-align: center;

color: #838383;
}

.sidebar-accrued-table {
    margin: 30px 20px;
}
.sidebar-accrued-table .agenda-table>tr>th {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;    
    width: 70%;
}
.sidebar-accrued-table .agenda-table>tr>td {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;  
    width: 70%;
  
}

.subscription-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
    height: 400px;
    background-color: white;
    box-shadow: 0px 3px 9px 0px #00000040;
    padding: 0 5%;
}
.subscription-dialog>h1 {
    font-family: Montserrat;
font-size: 24px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: center;
color: #141414;
margin: 0
}
.subscription-dialog .buttons{
    width: 100%;
}
.subscription-dialog .continue-button {
    background: #d37498;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 20px;
}
.subscription-dialog .back-button {
    background: #2F2E2E;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
}
.tooltip-content{
    font-family: Montserrat;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: white;
}
.sectiondashboard{
    display: flex;
    height: 100vh;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 45px;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
    margin-top: 10px;
}
.mb30{
    margin-bottom: 30px;
}

.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
    margin-top: 10px;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 15px;
}


*, *::before, *::after {
    box-sizing: inherit;
}
*, ::after, ::before {
    box-sizing: inherit;
}
user agent stylesheet
li {
    display: list-item;
    text-align: -webkit-match-parent;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 45px;
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
ul {
    list-style: none;
}
ul {
    list-style: none;
}
user agent stylesheet
ul {
    list-style-type: disc;
}
.pro-sidebar {
    color:  #2F2E2E;
}
.headingMain{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 36px;




color:  #2F2E2E;

}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #2F2E2E ;
 }
 .pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
    border-bottom: none;
}
.pera{
   
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:  #2F2E2E;
}

.heading2nd{


font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;

color:  #2F2E2E;
}
.heading3rd{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 22px;
/* identical to box height */


color:  #2F2E2E;
}

.mt40{
    margin-top: 40px;
}
.mt60{
    margin-top: 60px;
}
.mt30{
    margin-top: 30px;
}
.mt80{
    margin-top: 80px;
}

.dashBtn{
    background-color: #D37498;
    color: #fff;
    width: 60%;
}

.button-bottom{
    text-align: unset;
}
.changelink{
color: #D37498;
    
    width: 50%;

}
.pro-sidebar .pro-menu {
    /* margin-top: 50px; */
    padding-top: 10px;
    padding-bottom: 10px;
}

@media screen and (max-device-width:980px) {
    .button-bottom{
        text-align: center;
    }
}
.backlink{
    color: #6B3434;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 17px;
margin-right: 10px;
margin-left: -20px;
}
.ml50{
    margin-left: 50px;
}
.editText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

color: #C67070;
}

.panel {
    border-radius: 0px !important;
    box-shadow: 0 0.5em 1em -0.125em transparent;
    font-size: 1rem;
}
@media screen and (min-device-width: 538px)  and (max-device-width: 980px) {
  
    .subsCard {
        display: flex;
        flex-direction: column;
    }
    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        color: #141414;
        line-height: 32px;
    }
    .ml50{
        margin-left: 12px;
    }
    
}
@media screen and (min-device-width: 425px)  and (max-device-width: 700px) {
  
    .subsCard {
        display: flex;
        flex-direction: column;
    }
    .ml50{
        margin-left: 12px;
    }
    
}


@media screen and (min-device-width: 375px)  and (max-device-width: 420px) {
    .subsCard {
        display: flex;
        flex-direction: column;
    }
    .ml50{
        margin-left: 12px;
    }
    
 
}
@media screen and (min-device-width: 320px)  and (max-device-width: 360px) {
    .subsCard {
        display: flex;
        flex-direction: column;
    }
    .panel-head {
        width: 269px;
        height: 217px;
        border: 3px solid #C67070;
        box-sizing: border-box;
        border-radius: 20px;
    }
    .ml50{
        margin-left: 12px;
    }
    
}
.paymentInput{

}
.payIn{

}
.paymentInputDivDash{
    display: flex;
}
.input-card {
    width: 100%;
    border: 1px solid #6B3434;
    box-sizing:border-box;
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    border-radius: 4px;
    background-color: #FFF;
    line-height: 1.5;
    height: 2.5em;
    padding-bottom: calc(.5em - 1px);
    padding-left: calc(.75em - 1px);
    padding-right: calc(.75em - 1px);
    padding-top: calc(.5em - 1px);
}
.input-card:focus {
    border-color: 
    #D39FB2;
    box-shadow: 0 0 0 .125em rgba(184, 100, 149, 0.25);
}
.input-card:hover {
    border-color: #b5b5b5;
}
.customInput3{
    width: 100%;
}
.customInput4{
    width: 100%;
}
.mt20{
    margin-top: 20px;
}
.paymentInputDashDiv {
    display: flex;
    margin-left: 0% !important;
    margin-bottom: 20px;
}
.ml10{
    margin-left: 18px;
}



@media screen and (min-device-width: 1100px)  and (max-device-width: 1490px)  {
    .column.is-two-fifths {
        flex: none;
        width: 65% !important;
    }
}

  @media screen and (min-device-width: 770px)  and (max-device-width: 1080px)  {

    .column.is-two-fifths {
        flex: none;
        width: 80% !important;
    }
    .customInput1 {
        width: 158px;
    }
    .customInput2 {
        width: 158px;
    }
  }

  @media screen and (min-device-width: 548px)  and (max-device-width: 768px) {
  
    .column.is-two-fifths {
        flex: none;
        width: 76% !important;
    }
    .customInput1 {
        width: 158px;
    }
    .customInput2 {
        width: 158px;
    }
  }

  @media screen and (min-device-width: 425px)  and (max-device-width: 700px) {
  
    .column.is-two-fifths {
        flex: none;
        width: 90% !important;
    }
    .customInput1 {
        width: 158px;
    }
    .customInput2 {
        width: 158px;
    }
    
  }


  @media screen and (min-device-width: 375px)  and (max-device-width: 420px) {
    .column.is-two-fifths {
        flex: none;
        width: 100% !important;
    }
    .customInput1 {
        width: 150px;
    }
    .customInput2 {
        width: 150px;
    }
   
  }
  @media screen and (max-device-width:375px) {
    .column.is-two-fifths {
        flex: none;
        width: 100% !important;
    }
  }
  @media screen and (min-device-width: 320px)  and (max-device-width: 360px) {
    
    .customInput1 {
        width: 125px;
    }
    .customInput2 {
        width: 125px;
    }
  }
.logForm {

    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
    padding: 20px;

}

.sectiondashboard {
    min-height: 100vh;
    height: 100%;
}

.logForm>label {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    margin: 0;
    margin-top: 20px;
}

.logForm>input {
    margin-top: 1px;
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 5px 15px !important;

}

.logForm>select {
    margin-top: 1px;
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 5px 10px !important;

}
.Register__error {
    margin-left: 1%;
    color: red;
    font-size: 12px;
}

.dropdownCustom {
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #D37498;
    height: 30px;
}
.logForm>textarea {
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 20px !important;
}

.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.date-picker {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    padding: 0px 15px;
    margin-bottom: 20px;
    border: 1px solid #4B2929;
}

.date-picker:focus {
    border-color: #485fc7;
    box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
    outline: none;
}

.MuiOutlinedInput-root {
    width: 100%;
    height: 35px;
    border-radius: 5px;
    padding: 0px;
    border: 1px solid #4B2929;
}
.MuiMultiselect{
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
}

.month-card {
    width: 45%;
    box-shadow: 0px 3px 9px 0px #00000040;
    margin-left: 10%;
    padding: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.month-card .month{
    border: 1px solid #B7B7B7;
    color: white;
    background-color: #D37498;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #B7B7B7

}
.month-card .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 50px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #2F2E2E;
    border-bottom:  1px solid #B7B7B7;
    border-right: 1px solid #B7B7B7;
}
.month-card .check-label {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    width: 100%;
}
.loghourDiv{
    display: flex;
}
.logHourForm{
    width:40% ;
}
@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 705px) and (max-device-width: 1080px) {
    .loghourDiv{
        display: flex;
        flex-direction: column;
    }
    .logHourForm{
        width:85% ;
    }
    .month-card {
        width: 90%;
        box-shadow: 0px 3px 9px 0px #00000040;
        margin-left: 0%;
        padding: 0;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
    }
    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}




@media screen and (min-device-width: 425px) and (max-device-width: 700px) {

    .loghourDiv{
        display: flex;
        flex-direction: column;
    }
    .logHourForm{
        width:85% ;
    }
    .month-card {
        width: 90%;
        box-shadow: 0px 3px 9px 0px #00000040;
        margin-left: 0%;
        padding: 0;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
    }
}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
    
    .loghourDiv{
        display: flex;
        flex-direction: column;
    }
    .logHourForm{
        width:95% ;
    }
    .month-card {
        width: 100%;
        box-shadow: 0px 3px 9px 0px #00000040;
        margin-left: 0%;
        padding: 0;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
    }
}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {
    .loghourDiv{
        display: flex;
        flex-direction: column;
    }
    .logHourForm{
        width:95% ;
    }
    .month-card {
        width: 100%;
        box-shadow: 0px 3px 9px 0px #00000040;
        margin-left: 0%;
        padding: 0;
        height: -moz-fit-content;
        height: -webkit-fit-content;
        height: fit-content;
    }
    .headMain {
        font-size: 20px !important;
    }
}
.cards {
  background: #ffffff;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
  margin-top: 12px;
  padding: 20px;
  width: 30%;
  min-width: 280px;
  position: relative;
  height: auto;
}

.customModal {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customModal > p {
  font-weight: 600;
  font-size: 14px;
}

.mt30 {
  margin-top: 30px;
}

.mt25 {
  margin-top: 25px;
}

.react-responsive-modal-modal {
  max-width: 620px !important;
  height: 320px !important;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
}

.pacModal {
  width: 162px;
  height: 115px;
}

.pavlov {
  width: 59px;
  margin-left: 10px;
}

.dashHead {
  display: flex;
  align-items: center;
}

.tablevalue > td {
  text-align: center;
  padding: 16px;
  font-size: 14px;
  font-weight: 500;
}
.totalvalue > td {
  text-align: center;
  padding: 16px;
  font-size: 15px;
  font-weight: 700;
}

.bottomLogoNav {
  width: 92px;
  height: 32px;
  margin-left: 81px;
}

.card-text {
  font-weight: 600;
  font-size: 12px;
  margin-top: 12px;
  line-height: 20px;
}

.bottom {
  /* position: absolute; */
  /* bottom: 7%; */

  /* overflow-wrap: break-word; */
  text-align: center;
}

table > tr > th {
  background-color: #d37498 !important;
  color: white !important;
}

table {
  border: 1px solid #b7b7b7;
}

.section {
  overflow-x: auto !important;
}

.doughnut-div {
  width: 50%;
  max-width: 150px;
  margin: auto;
}

.doughnut-div-main {
  display: flex;
}
.percent-value {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}
.month {
  text-align: center;
  font-size: 20px;
}

.totalHours {
  text-align: center;
}

.font {
  color: #d37498;
  font-size: 12px;
}

.withoutcolor {
  font-size: 12px;
}

.colored {
  /* color: #D37498; */
  margin-bottom: -3px;
}

.hidden {
  visibility: hidden;
}

.monthCard {
  padding: 20px;
  width: 45%;
  height: 336px;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
}

/* .headMain {

    font-size: "24px";
} */

@media only screen and (max-width: 1070px) {
  .monthCard {
    height: 366px;
  }
  .agendaTable {
    width: 100%;
    overflow-x: auto;
  }
  
}

@media only screen and (max-width: 945px) {
  .monthCard {
    height: 386px;
  }
}

@media only screen and (max-width: 827px) {
  .monthCard {
    height: 406px;
  }
}

@media only screen and (max-width: 807px) {
  .monthCard {
    height: 425px;
  }
}

@media only screen and (max-width: 790px) {
  .monthCard {
    height: 475px;
  }

  .headMain {
    font-size: 24px;
  }
  .agendaTable {
    width: 100%;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 728px) {
  .monthCard {
    height: 575px;
  }
}

@media only screen and (max-width: 635px) {
  .agendaTable {
    width: 100%;
    overflow-x: auto;
  }
  .headingMain {
    font-size: 26px !important;
  }
  .monthCard {
    height: 675px;
  }
}

@media only screen and (max-width: 594px) {
  .monthCard {
    height: 805px;
  }
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  table>tr>th{
      
      background: #F4DEEA;
      border: none;
      text-align: center;
    
  }
  .Flagtable>tr>th{
   background-color: 
   #F4DEEA;
    border: none;
    text-align: center;
  }
  .tableFlex{
    display: flex;
    justify-content: space-between;
  }
  .statusSpanTable{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    color: #D37498;
  }

  .editSpanTable{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 17px;
/* identical to box height */


color: #C67070;

  }
  .barTableHeader{
    background: 
    #D37498;
border: 1px solid #B7B7B7;
box-sizing: border-box;
  }
  .colSize{
    width: 40px;
  }
  table>tr>td{
    background-color: white;
  }

  .empty-list {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9b9b9b;
  }




  @media screen and (min-device-width: 548px)  and (max-device-width: 768px) {
    .attachment {
        background: rgba(247, 233, 205, 0.3);
        border: 1px dashed #C4C4C4;
        box-sizing: border-box;
        width: 430px !important;
        height: 190px;
    }
  }

  @media screen and (min-device-width: 425px)  and (max-device-width: 700px) {
    .attachment {
     
      width: 360px !important;
      height: 190px;
  }

  }


  @media screen and (min-device-width: 375px)  and (max-device-width: 420px) {
    .attachment {
     
      width: 330px !important;
      height: 190px;
  }
   
  }
  @media screen and (min-device-width: 320px)  and (max-device-width: 360px) {
    .attachment {
     
      width: 270px !important;
      height: 190px;
  }
  
  }
input[type="checkbox"] {
    vertical-align: middle;
}

.selectBtn {
    -webkit-appearance: none;
            appearance: none;
    display: inline;
}

.monthlyTable>tr>td {
    border: none;
}

.monthlyTable>tr>td>select {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    width: 70px;
    height: 30px;
    border: 1px solid #2F2E2E;
    box-sizing: border-box;
    border-radius: 5px;
    color: #2F2E2E;
}

.checkSize {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    border: 1px solid #000000;
    box-sizing: border-box;
    vertical-align: middle;
    margin: 10px 10px;
    margin-left: 0;
}

.select:not(.is-multiple) {
    height: 35px;
}

.HRline {
    width: 100%;
    height: 0px;
    border: 1px solid #2F2E2E;
    margin-bottom: 20px;
}

.clr1 {
    background: #F9E18A;
    border: 1px solid #F9E18A;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    margin: 5px 15px;
    height: 22px;
}

.clr2 {
    background: #75D6D1;
    border: 1px solid #75D6D1;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.clr3 {
    background: #FF89B1;
    border: 1px solid #FF89B1;
    box-sizing: border-box;
    border-radius: 5px;
    width: 68px;
    height: 22px;
    margin: 5px 15px;
}

.secondSectionMonthlyDocumnet {
    display: flex;
    align-items: center;
}

.ml160 {
    margin-left: 200px;
}

.mt10 {
    margin-top: 10px;
}

.secondSectionMonthlyDocumnet>div>p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #2F2E2E;

}

.colordtext>div>span {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #2F2E2E;
    padding: 0px;

}

.Heading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */


    color: #2F2E2E;

}

.mt5 {
    margin-top: 5px;
}

.headerChart {
    width: 100%;
    height: 96px;
    text-align: center;
    display: flex;
    background: #D37498;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);


    background: #D37498;
}

.headerChart>p {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    -webkit-font-feature-settings: "smcp";
            font-feature-settings: "smcp";
    font-variant: small-caps;
    text-align: center;

    color: #FFFFFF;

}

.contactAgendaInput {
    border: none;
    background-color: transparent;
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
}

.agendaSec1 {
    display: flex;
    flex-wrap: wrap;
}

.agendaSec1>div>p {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    color: #141414;
    margin: 20px;

}

.saveBtn {
    background-color: #D37498;
    color: #fff;
    width: 200px;
}

.checkbox-label {

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 30px;
    color: #141414;
    margin: 20px;
}

.checkbox-label>span {
    vertical-align: middle;

}

.subHeading {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 22px;
    color: #141414;
}

.agendasec2 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.agenda-table>tr>th {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.agenda-table>tr>td {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
}

.colSize1 {
    width: 100px;
    text-align: center;
}

.checkText {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17.07px;
    color: #141414;

}

.textareA {
    width: 100%;
}

table>tr>td {
    color: black;
}

label {
    color: black;
}

.dropdownCustomize {
    width: 40%;
}

p,
label {
    color: black;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
}

.inputagenda {
    border: none;
    border-bottom: 1px solid #B7B7B7;
    margin-left: 15px;
}

.check {
    display: flex;
    flex-wrap: wrap;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.check>* {
    width: 50%;
    height: 30px;
}

.check>p {
    padding: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    width: 320px;
    font-size: 13px;
    /* identical to box height */


    color: #141414;

}

.inputFlex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

}

.inputagenda2 {
    border: none;
    border-bottom: 1px solid #B7B7B7;
    width: 320px;
}
.inputagenda3 {
    border: none;
    border-bottom: 1px solid #B7B7B7;
    width: 100%;
    height: 30px;
}

.agendaTable>table>tr>td {
    height: 25px;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;
    vertical-align: middle;
}

.agendaTable>table>tr>th {
    height: 30px;
    text-align: center;
    vertical-align: middle;

}

.pro-sidebar .pro-menu .pro-menu-item.active {
    color: #2F2E2E;
    font-weight: bold;
}


.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
    margin-right: 10px;
    font-size: 20px;
    width: 35px;
    min-width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
}

.pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
    flex-grow: 1;
    font-size: 16px;
    flex-shrink: 1;
}

.agendaTableHeader {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #141414;
    vertical-align: middle;

}

.agendaTableText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    color: #141414;

}

@media screen and (max-device-width: 715px) {
    .dropdownCustomize {
        width: unset;
    }
}

@media screen and (max-device-width: 350px) {
    .inputagenda {
        width: 100%;
    }

    .saveBtn {
        width: 80%;
    }
}

.dropdownCustomize2 {
    width: 100px;
    height: 20px;
    font-size: 14px;
    border: none;
    vertical-align: unset;
    line-height: 14px;
    margin-left: 10px;
}


@media screen and (min-device-width: 705px) and (max-device-width: 1080px) {
    
    .monthlyTableMove{
        width: 100%;
            overflow-x: auto;
      }
      .secondSectionMonthlyDocumnet {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ml160 {
        margin-left: 0px;
    }

}




@media screen and (min-device-width: 425px) and (max-device-width: 700px) {

    .monthlyTableMove{
        width: 100%;
            overflow-x: auto;
      }
      .secondSectionMonthlyDocumnet {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ml160 {
        margin-left: 0px;
    }
   
}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
    .monthlyTableMove{
        width: 100%;
            overflow-x: auto;
      }
      .secondSectionMonthlyDocumnet {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ml160 {
        margin-left: 0px;
    }
  
  
}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .monthlyTableMove{
    width: 100%;
        overflow-x: auto;
  }
  .secondSectionMonthlyDocumnet {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ml160 {
    margin-left: 0px;
}
}
.dashForm > label{
    color: black;
    margin-left: 0px !important;
    font-size: 14px;
line-height: 17px;
font-family: Montserrat;
font-style: normal;

}

.flagged {
    color: red;
    margin-left: 3px !important;
    font-size: 11px;
    line-height: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;

}
.mt20 {
    margin-top: 20px;
}

.badges {
    display: flex;
    justify-content: space-between;
}

.mainBadges {
    width: 696px;
    height: 305px;
    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
    padding: 16px;
    margin-top: 130px;
}

.mainBadges>p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.nestedDivBadges {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.mt50 {
    margin-top: 40px;
}
.nestedDivBadges>p{
    margin-top: 15px;
}


.light{
    opacity: 0.2;

}
.dark{
    opacity: 1.0;

}


.page-not-found {
    display: flex;
    flex-direction: column;
    margin: auto;
    align-items: center;
}

.page-not-found>h1 {
    font-size: 150px;
    font-weight: 900;
    margin-bottom: 20px;
    color: #dd1010a1;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Standard */
}

.page-not-found>h3 {
    font-size: 30px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
    color: #9d9191;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Standard */

}

.page-not-found>p {
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 50px;
    color: #9d9191;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none; /* Standard */
    text-align: center;

}

.page-not-found>button {
    padding: 15px 30px;
    background-color: #ff0000a1;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    font-size: 20px;
    border: 0;
    border-radius: 25px;
    cursor: pointer;
}
.page-not-found>button:hover{
    color: #a49292;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}

div#root {
  min-width: 100vw;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe{
  display: none;
  z-index: -20;
}

.Toggle_btn{
    display: none;
}
.headingMain {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #2F2E2E;
    margin-bottom: 20px;
}

h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #6B3434;
    line-height: 32px;
}
h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    line-height: 50px;
}
h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666;
    line-height: 40px;
}
p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;

}
ul {
    list-style:inherit;
    list-style-type: disc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    margin-left: 24px;

}

@media screen and (max-device-width: 576px)   {
    .Toggle_btn{
        display: block;
        font-size: 30px;
        color: #4B2929;
    }
    h2 {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        color: #6B3434;
        line-height: 32px;
    }
  }
.forgot-password-text-sub {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #141414;
  margin-left: 100px;
}

.cancel-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 15px;
  text-align: left;
  color: #141414;
}

@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .welcome-text {
    font-size: 40px;
  }
}

@media screen and (max-device-width: 360px) {
  .forgot-password-text-sub {
    font-size: 12px;
    margin-left: 10px;
  }
}

@media screen and (max-device-width: 780px) {
  .cancel-text {
    color: #ff0053;
  }
}

.Register__error {
  margin-left: 1%;
  color: red;
}

.terms-checkbox {
  width: 16px;
  height: 16px;
}

.register-checkbox-text {
  margin-left: 16px;
  word-wrap: break-word;
  font-size: small;
  text-align: right;
}
.register-checkbox-text>a {
  text-decoration: none;
  color: #D37498;
  font-weight: bold;
}
.has-left-background {
  background-image: url("../../assets/left_side_background.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}

.supervisor-signup-text {
  margin-left: 2px;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .register-image {
    width: 271px;
    height: 147px;
  }

  .registration-text {
    font-size: 48px;

    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .register-form-layout {
    margin-left: 23%;
  }

  .register-form-layout2 {
    margin-left: 0%;
  }

  .register-terms-layout {
    margin-left: 5%;
    width: 80%;
    color: #141414;
  }

  .register-terms-layout2 {
    margin-left: 20px;
    width: 80%;
    color: #141414;
  }

  .checkbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: #141414;
    font-size: 14px;
    /* margin-left: 30%; */
  }

  .checkbox2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: #141414;
    font-size: 14px;
    margin-left: 0;
  }

  .signup-text {
    font-size: 12px;
    margin-top: -20px;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }
}

.column.is-half {
  flex: none;
  width: 70%;
}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {
  .column.is-half {
    flex: none;
    width: 70%;
  }

  .register-image {
    width: 271px;
    height: 147px;
  }

  .registration-text {
    font-size: 48px;

    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .register-form-layout {
    margin-left: 40%;
  }

  .checkbox {
    font-size: 14px;
    margin-left: 18%;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }
}

@media screen and (max-device-width: 540px) {
  .column.is-half {
    width: 100%;
  }
}

@media screen and (min-device-width: 548px) and (max-device-width: 768px) {
  .column.is-half {
    flex: none;
    width: 70%;
  }

  .register-image {
    width: 271px;
    height: 147px;
  }

  .registration-text {
    font-size: 48px;

    align-self: center;
    justify-content: center;
    align-items: center;
  }

  .register-form-layout {
    margin-left: 0%;
  }
}

@media screen and (min-device-width: 425px) and (max-device-width: 700px) {
  .register-form-layout {
    margin-left: 0%;
  }
}

@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .register-form-layout {
    margin-left: 0%;
  }

  .signup-text {
    font-size: 14px;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }

  .register-checkbox-text {
    word-wrap: break-word;
    font-size: 10px !important;
    text-align: center;
  }

  .checkbox {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    color: #141414;
    font-size: 9px !important;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .register-image {
    width: 271px;
    height: 147px;
    margin-left: 0%;
  }

  .register-form-layout {
    margin-left: 0%;
  }

  .register-checkbox-text {
    word-wrap: break-word;
    font-size: 8px !important;
    text-align: center;
  }

  .checkbox {
    font-size: 9px !important;
  }

  .signup-text {
    font-size: 14px;
  }

  .supervisor-signup-text {
    margin-left: 0px;
  }

  .registration-text {
    font-size: 35px;
    margin-left: 12%;
  }
}

@media screen and (max-device-width: 780px) {
  .register-checkbox-text,
  .registration-text {
    color: #ff0053;
  }
}

.attachment{
    background: rgba(247, 233, 205, 0.3);
border: 1px dashed #C4C4C4;
box-sizing: border-box;
width: 530px;
height: 190px;
}

.add-files{
    text-align: center;
}
.DragText{
    font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 64px;



text-align: center;

color: #000000;
}
.DropText{
    font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 28px;

text-align: center;

color: #838383;
}

.sidebar-accrued-table {
    margin: 30px 20px;
}
.sidebar-accrued-table .agenda-table>tr>th {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;    
    width: 70%;
}
.sidebar-accrued-table .agenda-table>tr>td {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;  
    width: 70%;
  
}

.subscription-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 30%;
    height: 400px;
    background-color: white;
    box-shadow: 0px 3px 9px 0px #00000040;
    padding: 0 5%;
}
.subscription-dialog>h1 {
    font-family: Montserrat;
font-size: 24px;
font-weight: 600;
line-height: 29px;
letter-spacing: 0em;
text-align: center;
color: #141414;
margin: 0
}
.subscription-dialog .buttons{
    width: 100%;
}
.subscription-dialog .continue-button {
    background: #d37498;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 20px;
}
.subscription-dialog .back-button {
    background: #2F2E2E;
    border-radius: 30px;
    width: 100%;
    height: 60px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #ffffff;
}
.tooltip-content{
    font-family: Montserrat;
font-size: 12px;
font-weight: 500;
line-height: 15px;
letter-spacing: 0em;
text-align: left;
color: white;
}
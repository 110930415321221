.is-horizontal-center {
  justify-content: center;
}

.column {
  padding-top: 0px;
}

.bottom-logo {
  width: 231px !important;
  height: 80px;
}

.role-button {
  width: 15rem;
  border: none;
}

.role-button-student {
  background-color: #D37498;
  color: #FFFFFF;
}

.role-button-trainee {
  background-color: #2F2E2E;

  color: #FFFFFF;
}

.role-button-trainee:hover {
  background-color: #2F2E2E;

  color: #D37498;
}

.button-container {
  height: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

button {
  background: none;
  border: none;
  outline: inherit;
  font-size: 12px;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .log {
    width: 500px !important;
  }

  .bottom-logo {
    width: 251px !important;
  }
}

@media only screen and (min-width: 700px) {
  .log {
    margin-left: 0px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .log {
    margin-left: 30px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 1800px) {}
.Register__error {
    margin-left: 1%;
    color: red;
}

.text-sub {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #6B3434;
    margin-left: 8%;
    }

.btnself {
        width: 510px;
}

@media only screen and (max-width: 1300px) {

        .btnself {
                width: 480px
        }
}

@media only screen and (max-width: 1260px) {

        .btnself {
                width: 450px
        }
}

@media only screen and (max-width: 1190px) {

        .btnself {
                width: 400px
        }
}

@media only screen and (max-width: 1090px) {

        .btnself {
                width: 380px
        }
}

@media only screen and (max-width: 970px) {

        .btnself {
                width: 360px
        }
}

@media only screen and (max-width: 900px) {

        .btnself {
                width: 320px
        }
}

@media only screen and (max-width: 490px) {

        .btnself {
                width: 220px
        }
}

/* @media screen and (min-device-width: 900px) and (max-device-width: 1300px) {
} */
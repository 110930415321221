.mt20 {
    margin-top: 20px;
}

.badges {
    display: flex;
    justify-content: space-between;
}

.mainBadges {
    width: 696px;
    height: 305px;
    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
    padding: 16px;
    margin-top: 130px;
}

.mainBadges>p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.nestedDivBadges {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.mt50 {
    margin-top: 40px;
}
.nestedDivBadges>p{
    margin-top: 15px;
}


.light{
    opacity: 0.2;

}
.dark{
    opacity: 1.0;

}


.dashForm > label{
    color: black;
    margin-left: 0px !important;
    font-size: 14px;
line-height: 17px;
font-family: Montserrat;
font-style: normal;

}

.flagged {
    color: red;
    margin-left: 3px !important;
    font-size: 11px;
    line-height: 14px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-style: italic;

}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  table>tr>th{
      
      background: #F4DEEA;
      border: none;
      text-align: center;
    
  }
  .Flagtable>tr>th{
   background-color: 
   #F4DEEA;
    border: none;
    text-align: center;
  }
  .tableFlex{
    display: flex;
    justify-content: space-between;
  }
  .statusSpanTable{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    color: #D37498;
  }

  .editSpanTable{
    font-family: Montserrat;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 17px;
/* identical to box height */


color: #C67070;

  }
  .barTableHeader{
    background: 
    #D37498;
border: 1px solid #B7B7B7;
box-sizing: border-box;
  }
  .colSize{
    width: 40px;
  }
  table>tr>td{
    background-color: white;
  }

  .empty-list {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9b9b9b;
  }

.termAndCondText{
    margin-left: 30px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #2F2E2E;
}
.headingMain {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    color: #2F2E2E;
    margin-bottom: 20px;
}
h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    color: #6B3434;
    line-height: 32px;
}
h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    color: #000;
    line-height: 50px;
}
h4 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #666;
    line-height: 40px;
}
p{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;

}
ol {
    list-style:inherit;
    list-style-type: disc;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    margin-left: 24px;

}

.mt20{
    margin-top: 20px;
}
.profile .primary-button {
    height: 46px;
    width: 204px;
    background: #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 30px;
}
.profile .label {
    font-family: Montserrat;
font-size: 14px;
font-weight: 500;
line-height: 17px;
letter-spacing: 0em;
text-align: left;
color: #6B3434;
margin-left: 0px;
margin-bottom: 6px;
}

.profile .text-input {
    font-family: Montserrat;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
text-align: left;
color: #6B3434;
height: 36px;
}

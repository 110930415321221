.logForm {

    background: #FFFFFF;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.25);
    padding: 20px;

}

.sectiondashboard {
    min-height: 100vh;
    height: 100%;
}

.logForm>label {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #141414;
    margin: 0;
    margin-top: 20px;
}

.logForm .log-item {
    margin-top: 1px;
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    color:#141414;

}
.logForm .log-item-selected {
    margin-top: 1px;
    border: 2px solid #D37498;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 5px 15px !important;
    color:#D37498;


}
.logForm .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.logForm .primary-button {
    height: 42px;
    width: 48%;
    background: 
    #D37498;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}

.logForm .secondary-button {
    height: 42px;
    width: 48%;
    background: 
    #2F2E2E;
    border-radius: 30px;
    color: #FFFFFF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
}
.logForm .text-button {
    height: 18px;
    width: auto;
    background: #FFFFFF;
    border-radius: 30px;
    color: #141414;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    }
.logForm .buttons>p{
    font-family: Montserrat;
font-size: 14px;
font-weight: 600;
line-height: 17px;
letter-spacing: 0em;
text-align: center;
color: #C67070;
margin: 16px 0;
}
.logForm .full-width {
    width: 80%;
    margin: 0 5%;
}
.Register__error {
    margin-left: 1%;
    color: red;
    font-size: 12px;
}

.dropdownCustom {
    box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
    max-width: 100%;
    width: 100%;
    background-color: #fff;
    border-color: #dbdbdb;
    border-radius: 4px;
    color: #363636;
    height: 30px;
}

.logForm>textarea {
    border: 1px solid #4B2929;
    box-sizing: border-box;
    border-radius: 5px !important;
    padding: 20px !important;
}

.column.is-two-fifths,
.column.is-two-fifths-tablet {
    flex: none;
    width: 45%;
}

.date-picker {
    width: 100%;
    height: 32px;
    border-radius: 5px;
    padding: 0px 15px;
    margin-bottom: 20px;
    border: 1px solid #4B2929;
}

.date-picker:focus {
    border-color: #485fc7;
    box-shadow: 0 0 0 .125em rgba(72, 95, 199, .25);
    outline: none;
}


@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {

    .headingMain {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

    .heading_Main {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        color: #6B3434;
    }

}



@media screen and (min-device-width: 425px) and (max-device-width: 700px) {}


@media screen and (min-device-width: 375px) and (max-device-width: 420px) {}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {}
.no-internet-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;  
}
h1,
h2 {
  font-weight: normal;
}
h1 {
  margin: 30px;
  margin-top: 100px;
  padding: 0.15 em;
  font-size: 150px;
  text-shadow: 0 1px 1px #aaa;
}

h2 {
  margin-bottom: 1em;
}
.desc {
  color: #999;
}

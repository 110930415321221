.mtPrompt{
    margin-top: 240px;
}
.promptText{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 15px;

color: #6B3434;
}
.has-background {
  background-image: url("../../assets/right_side_background.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 100vh;
}

.has-light-background {
  background-image: url("../../assets/right-side-light-background.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 100vh;
}

.section {
  width: 100%;
}

.has-left-background {
  background-image: url("../../assets/left_side_background.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}

.has-left-light-background {
  background-image: url("../../assets/left_side_background.png");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}

.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.remember-me-layout {
  margin-left: 4%;
  width: 100vh;
  color: #141414;
}

.forgot-pass {
  color: #141414;
  text-decoration: underline;
  width: 100%;
}

.welcome-text {
  width: 100%;
  margin-left: 70px;
  font-family: Suranna;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 48px;
  line-height: 63px;
  color: #141414;
}

.registration-text {
  color: #141414;
  font-family: Suranna;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  text-align: center;
  line-height: 63px;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.register-image {
  width: 271px;
  height: 147px;
  margin-left: 12%;
}

.label {
  color: #141414;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  margin-left: 15px;
}

.has-input-background {
  box-sizing: border-box;
  border: 1px solid #939597;
  border-radius: 5px;
}

.signin-button {
  background: #d37498;
  border-radius: 30px;
  width: 100%;
  height: 60px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;

  color: #ffffff;
}

.form-layout {
  margin-left: 2%;
}

.register-form-layout {
  margin-left: 5%;
}

.signup-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #141414;
}

.bottom-text-div {
  text-align: center;
}

.log-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #141414;
}

.Logo {
  width: 271px;
  height: 147px;
  margin-left: 30%;
}

.row-flex .checkbox {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: #141414;
  font-size: 12px;
  margin-left: 10px;
}

.customFlex {
  width: 500px;
}

@media screen and (min-device-width: 1100px) and (max-device-width: 1490px) {
  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 20%;
  }

  .customFlex {
    width: 307px;
  }

  .forgot-password-text-sub {
    margin-left: 63px;
  }
}

@media screen and (min-device-width: 770px) and (max-device-width: 1080px) {
  .column.is-one-quarter,
  .column.is-one-quarter-tablet {
    flex: none;
    width: 45%;
  }

  .column.is-one-third {
    width: 45%;
  }

  .forgot-password-text-sub {
    margin-left: 50px !important;
  }

  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 18%;
  }

  .customFlex {
    width: 600px;
    margin-left: -12px;
  }
}

@media screen and (max-device-width: 780px) {
  .log-text,
  .signup-text,
  .register-checkbox-text {
    color: #ff0053;
  }

  .Logo,
  .register-image {
    background-color: whitesmoke;
    border-radius: 12px;
  }
}

@media screen and (min-device-width: 750px) and (max-device-width: 1500px) {
  .welcome-text {
    margin-left: 40px;
  }
}

@media screen and (min-device-width: 548px) and (max-device-width: 768px) {
  .customFlex {
    width: 307px;
  }
}

@media screen and (min-device-width: 425px) and (max-device-width: 700px) {
  .welcome-text {
    margin-left: 12%;
  }

  .Logo {
    width: 271px;
    height: 147px;
  }

  .customFlex {
    width: 307px;
  }

  .form-layout {
    margin-left: 0%;
  }

  .forgot-password-text-sub {
    font-size: 12px;
    line-height: 15px;

    margin-left: 10px !important;
  }
}

@media screen and (max-device-width: 420px) {
  .welcome-text {
    margin-left: 9%;
    font-size: 35px;
  }
}

@media screen and (min-device-width: 375px) and (max-device-width: 420px) {
  .forgot-password-text-sub {
    font-size: 12px;
    line-height: 15px;

    margin-left: 10px !important;
  }

  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 12%;
  }

  .customFlex {
    width: 307px;
  }

  .form-layout {
    margin-left: 0%;
  }
}

@media screen and (max-device-width: 375px) {
  .Logo {
    width: 271px;
    height: 147px;
    margin-left: 0%;
  }
}

@media screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .welcome-text {
    margin-left: 9%;
    font-size: 35px;
  }

  .forgot-password-text-sub {
    font-size: 12px;
    line-height: 15px;

    margin-left: 10px;
  }

  .form-layout {
    margin-left: 0%;
  }

  .customFlex {
    width: 307px;
  }

  .log-text {
    font-size: 14px;
  }
}
